<!--eslint-disable-->
<template>
  <PageCover :active-path="'comunicat-de-presa'"
             image="/img/cover-rezervari.webp"
             page-title="Comunicat de presă">
  </PageCover>
  <div class="mt-5 comunicat py-4">
    <section class="container text-start">
      <div class="text-center mb-2">
        <img src="img/guvern.png" width="250" class="img-fluid">
      </div>
      <div class="text-center mb-4">
        <img src="img/cofinantat.png" class="img-fluid">
      </div>
      <div class="text-black px-5 mt-5 container-sm">
        <h1 class="text-center">Descrierea proiectului</h1>

        <h2 class="text-center mb-5">„DEZVOLTAREA ACTIVITĂȚII SOCIETĂȚII BUNKER SOCIAL GAMING ARENA SRL”</h2>

        <p>
          Beneficiarul <strong>BUNKER SOCIAL GAMING ARENA SRL</strong> anunta inceperea proiectului „Dezvoltarea activității societății BUNKER SOCIAL GAMING ARENA SRL” SMIS 325168, finantat prin Programul Tranzitie Justa, Prioritatea 3. Atenuarea impactului tranziției la neutralitatea climatică în județul Dolj, Obiectiv specific JSO8.1_A permite regiunilor și cetățenilor să facă față efectelor sociale, asupra ocupării forței de muncă, economice și de mediu ale tranziției către țintele energetice și climatice ale Uniunii pentru 2030 și o economie neutră din punct de vedere climatic a Uniunii până în 2050, în temeiul Acordului de la Paris, Apel: DJ - Investitii productive IMM.
        </p>

        <p>
          <strong>Obiectivul general</strong> al proiectului il constituie imbunatatirea competatitivitatii intreprinderii BUNKER SOCIAL GAMING ARENA SRL prin realizarea unei investiții in domeniul activitatilor creative de petrecere a timpului liber, de tipul: jocurilor video, jocurilor de realitate virtuala, bowling si acrobatii in trambuline, organizare evenimente in vederea unei diversificări durabile din punct de vedere al mediului si în vederea creșterii competitivității și capacității societatii de a genera locuri de muncă durabile, sigure și cu un nivel de salarizare atractiv.
        </p>

        <p>
          <strong>Obiectivele specifice</strong> ale proiectului sunt:
        </p>
        <ol>
          <li>
            Dotarea cu echipamente tehnologice, utilaje, instalatii de lucru, mobilier, echipamente informatice, birotica specifice activitatii CAEN 9329 dupa cum urmeaza:
            <ul>
              <li>Arena jocuri realitate virtuală – 1 buc.</li>
              <li>Arena laser - 1 buc.</li>
              <li>Ring de dans interactiv – 1 buc.</li>
              <li>Cub 5D cu proiecție interactivă – 1 buc.</li>
              <li>Simulator schi – 1 buc.</li>
              <li>Parc trambuline – 1 buc.</li>
              <li>Videoproiector – 1 buc.</li>
              <li>Bowling – 2 buc.</li>
            </ul>
          </li>
          <li>
            Respectarea si promovarea principiilor orizontale privind obiectivele de mediu si egalitatea de sanse si tratament prin:
            <ul>
              <li>Utilizarea surselor regenerabile de energie/ utilizarea unor sisteme/instalații/echipamente de încălzire/răcire, climatizare, de ventilare mecanică si altele, în scopul reducerii consumului energetic din surse convenționale și a emisiilor de gaze cu efect de seră.</li>
              <li>Minimizarea la sursa a deseurilor generate prin incheierea de acorduri/contracte cu societăți de reciclare pentru deseurile rezultate din activitatea propusa prin proiect.</li>
              <li>Adaptarea infrastructurii, inclusiv a echipamentelor si utilajelor pentru accesul si operarea de catre persoane cu dizabilitati prin achizitia unei rampe mobile pentru a facilita accesul persoanelor cu dizabilitati, in special a celor imobilizate in scaunul cu rotile, in locatia de implementare a proiectului.</li>
              <li>Crearea de 3 noi locuri de munca, de care vor beneficia locuitorii zonei si in special cei ce provin din industria/ramură economică direct afectată de procesul de transformare în contextul procesului de tranziție justă (ex. producția de energie electrică și termică pe bază de cărbune, industria automobilelor, de material plastic primar sau de utilaje pentru industria metalurgică și minieră).</li>
              <li>Integrarea persoanelor din categorii defavorizate pe piata muncii prin crearea de minim locuri de munca pentru acestea, în special pentru tinerii cu vârsta de până la 29 ani.</li>
              <li>Includerea persoanelor de sex feminin atat in echipa de operare a investitiei.</li>
            </ul>
          </li>
        </ol>

        <p>
          <strong>Rezultatele asteptate</strong> ale proiectului sunt:
        </p>
        <ol>
          <li>Proiect implementat conform documentatiei propuse: Documentatie proiect elaborata: cerere de finantare elaborata si plan de afaceri elaborat.</li>
          <li>Dotarea cu active corporale (12 buc.).</li>
          <li>La finalul implementarii proiectului se va realiza angajarea personalului suplimentar necesar, din care 3 persoane provin din activități economice dintr-o industrie/ramură economică direct afectată de procesul de transformare în contextul procesului de tranziție justă în judetul Dolj (ex. producția de energie electrică și termică pe bază de cărbune, industria automobilelor, de material plastic primar sau de utilaje pentru industria metalurgică și minieră) si 2 persoane din categoria tinerii cu vârsta de până la 29 ani.</li>
        </ol>

       <p class="pt-4">
         <strong>Perioada de implementare</strong> a proiectului este de 12 luni de la data semnarii contractului de finantare, respectiv incepe in data de 21.02.2025 si se finalizeaza in 20.02.2026.
       </p>

        <p>
          <strong>Locație de implementare:</strong> Municipiul Craiova, Bulevardul Nicolae Romanescu, Nr. 112, Cladirea C1, Judet Dolj.
        </p>

        <div class="pt-4">
          <p><strong>Valoarea totala a proiectului:</strong> 4.013.975,98 Lei</p>
          <p><strong>Valoare finantare UE din FTJ:</strong> 2.092.298,88 Lei</p>
          <p><strong>Valoare finantare bugetul national:</strong> 369.229,21 Lei</p>
          <p><strong>Valoare cofinantare eligibila beneficiar:</strong> 785.165,20 Lei</p>
          <p><strong>Valoare cofinantare neeligibila beneficiar:</strong> 767.282,69 Lei</p>
        </div>

        <p class="pt-5">
          <strong>
            Informatii suplimentare se pot obtine la:
          </strong>
        </p>
        <p>BUNKER SOCIAL GAMING ARENA SRL</p>
        <p>Dl. Alin-Mircea Vanță, Administrator</p>
        <p>Telefon: 0722.591.835</p>
        <p>
          Email:
          <a href="mailto:bunkerarena@gmail.com">
          bunkerarena@gmail.com</a></p>
        <p>
          Adresă:
          Municipiul Craiova, Strada Popoveni,
          Nr. 12, Bloc. I25, Scara 1, Apartament 8, Județ Dolj</p>

        <p class="pt-4"><a href="https://mfe.gov.ro/ptj/despre-program-programare/">Mai multe informații</a></p>

        <p>Conținutul acestui material nu reprezintă în mod obligatoriu
          poziția oficială a Uniunii Europene sau a Guvernului României.</p>
      </div>
    </section>
  </div>
  <Footer></Footer>
</template>

<script>
import PageCover from '../components/PageCover.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'Regulament',
  components: {
    PageCover,
    Footer,
  },
};
</script>

<style scoped lang="scss">
.comunicat {
  background-color: rgba(255, 255, 255, 1);
}

li {
  text-align: start;
}

h2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

a {
  color: black;
  font-weight: 600;
  text-decoration: underline;
}
</style>
